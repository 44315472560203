var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 9B"
    },
    B: {
      "2": "C K L G M",
      "33": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H",
      "129": "N O"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 AC rB I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB BC CC",
      "33": "eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB"
    },
    D: {
      "16": "I s J D E F A B C K",
      "33": "0 1 2 3 4 5 6 7 8 9 L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC"
    },
    E: {
      "2": "I EC xB",
      "33": "s J D E F A B C K L G FC GC HC IC yB oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC"
    },
    F: {
      "2": "F B C MC NC OC PC oB 7B QC pB",
      "33": "0 1 2 3 4 5 6 7 8 9 G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b"
    },
    G: {
      "2": "xB RC 8B",
      "33": "E SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B"
    },
    H: {
      "2": "lC"
    },
    I: {
      "16": "mC nC",
      "33": "rB I H oC pC 8B qC rC"
    },
    J: {
      "33": "D A"
    },
    K: {
      "2": "A B C oB 7B pB",
      "33": "d"
    },
    L: {
      "33": "H"
    },
    M: {
      "33": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "33": "sC"
    },
    P: {
      "33": "I tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "33": "zB"
    },
    R: {
      "33": "6C"
    },
    S: {
      "2": "7C"
    }
  },
  B: 5,
  C: "CSS line-clamp"
};
export default exports;