var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 9B"
    },
    B: {
      "1": "Y Z a b e f g h i j k l m n o p q r c H",
      "2": "C K L G M N O P Q",
      "257": "R S T U V W X"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "2": "AC rB I s J D E F A B C K L G M N O t u v w x y z BC CC"
    },
    D: {
      "1": "Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "2": "0 1 2 3 4 5 6 7 8 9 I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q",
      "257": "R S T U V W X"
    },
    E: {
      "1": "L G zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "2": "I s J D E F A B C K EC xB FC GC HC IC yB oB pB"
    },
    F: {
      "1": "mB nB P Q R uB S T U V W X Y Z a b",
      "2": "0 1 2 3 4 5 6 7 8 9 F B C G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB MC NC OC PC oB 7B QC pB",
      "257": "eB fB gB hB d iB jB kB lB"
    },
    G: {
      "1": "iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "132": "E xB RC 8B SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC"
    },
    H: {
      "2": "lC"
    },
    I: {
      "1": "H",
      "2": "rB I mC nC oC pC 8B qC rC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "d",
      "2": "A B C oB 7B pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "sC"
    },
    P: {
      "1": "2C qB 3C 4C 5C",
      "2": "I tC uC vC wC xC yB yC zC",
      "257": "0C 1C"
    },
    Q: {
      "2": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "1": "7C"
    }
  },
  B: 4,
  C: "CSS3 image-orientation"
};
export default exports;