var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 9B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H",
      "260": "C K L G M N O"
    },
    C: {
      "1": "TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "2": "AC rB I s J D E F A B C K L G M N O t BC CC",
      "66": "u v",
      "260": "0 1 2 3 4 5 6 7 8 9 w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB"
    },
    D: {
      "1": "tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "2": "0 1 I s J D E F A B C K L G M N O t u v w x y z",
      "260": "2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB"
    },
    E: {
      "1": "F A B C K L G IC yB oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "2": "I s J D E EC xB FC GC HC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b",
      "2": "F B C MC NC OC PC oB 7B QC",
      "132": "pB"
    },
    G: {
      "1": "WC XC YC ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "2": "E xB RC 8B SC TC UC VC"
    },
    H: {
      "132": "lC"
    },
    I: {
      "1": "H qC rC",
      "2": "rB I mC nC oC pC 8B"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "d",
      "2": "A B C oB 7B",
      "132": "pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "sC"
    },
    P: {
      "1": "I tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "1": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "1": "7C"
    }
  },
  B: 4,
  C: "CSS.supports() API"
};
export default exports;