var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 9B"
    },
    B: {
      "1": "X Y Z a b e f g h i j k l m n o p q r c H",
      "2": "C K L G M N O",
      "1220": "P Q R S T U V W"
    },
    C: {
      "1": "nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "16": "AC rB BC CC",
      "548": "0 1 2 3 4 5 6 7 8 9 I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB"
    },
    D: {
      "1": "X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "16": "I s J D E F A B C K L",
      "164": "0 1 2 3 4 5 6 7 8 9 G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB",
      "196": "bB cB dB",
      "1220": "eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W"
    },
    E: {
      "1": "L G JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "2": "I EC xB",
      "16": "s",
      "164": "J D E FC GC HC",
      "260": "F A B C K IC yB oB pB zB"
    },
    F: {
      "1": "kB lB mB nB P Q R uB S T U V W X Y Z a b",
      "2": "F B C MC NC OC PC oB 7B QC pB",
      "164": "0 1 2 3 4 5 6 7 8 9 G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB",
      "196": "QB RB SB",
      "1220": "TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB"
    },
    G: {
      "1": "iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "16": "xB RC 8B SC TC",
      "164": "E UC VC",
      "260": "WC XC YC ZC aC bC cC dC eC fC gC hC"
    },
    H: {
      "2": "lC"
    },
    I: {
      "1": "H",
      "16": "rB mC nC oC",
      "164": "I pC 8B qC rC"
    },
    J: {
      "16": "D",
      "164": "A"
    },
    K: {
      "1": "d",
      "2": "A B C oB 7B pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "164": "sC"
    },
    P: {
      "1": "2C qB 3C 4C 5C",
      "164": "I tC uC vC wC xC yB yC zC 0C 1C"
    },
    Q: {
      "1220": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "548": "7C"
    }
  },
  B: 5,
  C: ":is() CSS pseudo-class"
};
export default exports;