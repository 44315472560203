var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F 9B",
      "132": "A B"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "2": "AC rB",
      "260": "A B",
      "388": "J D E F",
      "900": "I s BC CC"
    },
    D: {
      "1": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "16": "I s J",
      "132": "3 4",
      "388": "0 1 2 D E F A B C K L G M N O t u v w x y z"
    },
    E: {
      "1": "E F A B C K L G HC IC yB oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "2": "I EC xB",
      "132": "D GC",
      "388": "s J FC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 C O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b pB",
      "2": "F B MC NC OC PC oB 7B QC",
      "132": "G M N"
    },
    G: {
      "1": "E VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "2": "xB RC 8B",
      "132": "UC",
      "388": "SC TC"
    },
    H: {
      "2": "lC"
    },
    I: {
      "1": "H rC",
      "2": "mC nC oC",
      "388": "qC",
      "900": "rB I pC 8B"
    },
    J: {
      "132": "A",
      "388": "D"
    },
    K: {
      "1": "C d pB",
      "2": "A B oB 7B"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "sC"
    },
    P: {
      "1": "I tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "1": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "1": "7C"
    }
  },
  B: 1,
  C: "XMLHttpRequest advanced features"
};
export default exports;