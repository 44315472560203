var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "J D 9B",
      "66": "E F A"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H"
    },
    C: {
      "1": "1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "2": "AC rB I s J D E F A B C K L G M N O t u v w x BC CC",
      "66": "0 y z"
    },
    D: {
      "1": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "2": "0 1 2 I s J D E F A B C K L G M N O t u v w x y z"
    },
    E: {
      "1": "D E F A B C K L G HC IC yB oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "2": "I s J EC xB FC GC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b",
      "2": "F G MC",
      "66": "B C NC OC PC oB 7B QC pB"
    },
    G: {
      "1": "E SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "2": "xB RC 8B"
    },
    H: {
      "1": "lC"
    },
    I: {
      "1": "H",
      "2": "rB I mC nC oC pC 8B qC rC"
    },
    J: {
      "1": "A",
      "2": "D"
    },
    K: {
      "1": "d pB",
      "2": "A B C oB 7B"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "1": "B",
      "66": "A"
    },
    O: {
      "1": "sC"
    },
    P: {
      "1": "I tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "1": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "1": "7C"
    }
  },
  B: 6,
  C: "TLS 1.2"
};
export default exports;