var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A 9B",
      "2052": "B"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H"
    },
    C: {
      "1": "IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "194": "0 1 2 3 4 5 6 7 8 9 AC rB I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB BC CC"
    },
    D: {
      "1": "NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "2": "I s J D E F A B C K L G M N O",
      "322": "0 1 2 3 4 5 6 7 8 9 t u v w x y z AB BB CB DB EB",
      "516": "FB GB HB IB JB KB LB MB"
    },
    E: {
      "1": "B C K L G oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "2": "I s J D E F EC xB FC GC HC IC",
      "1028": "A yB"
    },
    F: {
      "1": "AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b",
      "2": "F B C MC NC OC PC oB 7B QC pB",
      "322": "0 1 G M N O t u v w x y z",
      "516": "2 3 4 5 6 7 8 9"
    },
    G: {
      "1": "aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "2": "E xB RC 8B SC TC UC VC WC XC",
      "1028": "YC ZC"
    },
    H: {
      "2": "lC"
    },
    I: {
      "1": "H",
      "2": "rB I mC nC oC pC 8B qC rC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "d",
      "2": "A B C oB 7B pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "1": "sC"
    },
    P: {
      "1": "tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C",
      "516": "I"
    },
    Q: {
      "1": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "1": "7C"
    }
  },
  B: 6,
  C: "let"
};
export default exports;