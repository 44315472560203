var exports = {};
exports = {
  A: {
    A: {
      "1": "F A B",
      "2": "J D E 9B"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 AC rB I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB BC CC"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC"
    },
    E: {
      "1": "I s J D E F A B C K L G xB FC GC HC IC yB oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "16": "EC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 B C G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b MC NC OC PC oB 7B QC pB",
      "16": "F"
    },
    G: {
      "1": "E RC 8B SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "16": "xB"
    },
    H: {
      "1": "lC"
    },
    I: {
      "1": "rB I H oC pC 8B qC rC",
      "16": "mC nC"
    },
    J: {
      "1": "D A"
    },
    K: {
      "1": "A B C d oB 7B pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "sC"
    },
    P: {
      "1": "I tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "1": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "1": "7C"
    }
  },
  B: 1,
  C: "Node.textContent"
};
export default exports;