var exports = {};
exports = {
  A: {
    A: {
      "16": "9B",
      "132": "J D E F A B"
    },
    B: {
      "1": "O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H",
      "132": "C K L G M N"
    },
    C: {
      "1": "UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "132": "0 1 2 AC rB I s J D E F A B C K L G M N O t u v w x y z BC CC",
      "260": "QB RB SB TB",
      "772": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB"
    },
    D: {
      "1": "gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "132": "I s J D E F A B C K L G M N O t u v w x",
      "260": "CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB",
      "772": "0 1 2 3 4 5 6 7 8 9 y z AB BB"
    },
    E: {
      "1": "C K L G pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "16": "I s EC xB",
      "132": "J D E F A FC GC HC IC",
      "260": "B yB oB"
    },
    F: {
      "1": "VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b",
      "16": "F B C MC NC OC PC oB 7B QC",
      "132": "pB",
      "260": "0 1 2 3 4 5 6 7 8 9 z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB",
      "772": "G M N O t u v w x y"
    },
    G: {
      "1": "ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "16": "xB RC 8B SC",
      "132": "E TC UC VC WC XC YC"
    },
    H: {
      "132": "lC"
    },
    I: {
      "1": "H",
      "16": "rB mC nC oC",
      "132": "I pC 8B",
      "772": "qC rC"
    },
    J: {
      "132": "D A"
    },
    K: {
      "1": "d",
      "16": "A B C oB 7B",
      "132": "pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "sC"
    },
    P: {
      "1": "xC yB yC zC 0C 1C 2C qB 3C 4C 5C",
      "260": "I tC uC vC wC"
    },
    Q: {
      "1": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "132": "7C"
    }
  },
  B: 6,
  C: "Date.prototype.toLocaleDateString"
};
export default exports;