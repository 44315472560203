var exports = {};
exports = {
  A: {
    A: {
      "2": "F A B 9B",
      "8": "J D E"
    },
    B: {
      "2": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 AC rB I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB BC CC"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 I s J D E F A B C K L G M N O t u v w x y z AB BB",
      "2": "PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "130": "CB DB EB FB GB HB IB JB KB LB MB NB OB"
    },
    E: {
      "1": "I s J D E F A B C K L G xB FC GC HC IC yB oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "2": "EC"
    },
    F: {
      "1": "F B C G M N O t u v w x y MC NC OC PC oB 7B QC pB",
      "2": "BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b",
      "130": "0 1 2 3 4 5 6 7 8 9 z AB"
    },
    G: {
      "1": "E xB RC 8B SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B"
    },
    H: {
      "258": "lC"
    },
    I: {
      "1": "rB I pC 8B qC rC",
      "2": "H mC nC oC"
    },
    J: {
      "1": "D A"
    },
    K: {
      "1": "A B C oB 7B pB",
      "2": "d"
    },
    L: {
      "130": "H"
    },
    M: {
      "2": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "sC"
    },
    P: {
      "1": "I",
      "130": "tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "2": "zB"
    },
    R: {
      "130": "6C"
    },
    S: {
      "2": "7C"
    }
  },
  B: 2,
  C: "SVG fonts"
};
export default exports;