var exports = {};
exports = {
  A: {
    A: {
      "2": "F A B 9B",
      "8": "J D E"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H",
      "2": "C K L G M N O"
    },
    C: {
      "1": "NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "2": "AC",
      "8": "0 1 2 3 4 5 6 7 8 9 rB I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB BC CC",
      "194": "LB MB"
    },
    D: {
      "1": "AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "8": "I s J D E F A B",
      "257": "0 1 2 3 4 5 6 7 8 9 t u v w x y z",
      "769": "C K L G M N O"
    },
    E: {
      "1": "C K L G pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "8": "I s EC xB FC",
      "257": "J D E F A GC HC IC",
      "1025": "B yB oB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b",
      "2": "C oB 7B QC pB",
      "8": "F B MC NC OC PC"
    },
    G: {
      "1": "E TC UC VC WC XC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "8": "xB RC 8B SC",
      "1025": "YC ZC aC"
    },
    H: {
      "8": "lC"
    },
    I: {
      "1": "I H pC 8B qC rC",
      "8": "rB mC nC oC"
    },
    J: {
      "1": "A",
      "8": "D"
    },
    K: {
      "1": "d",
      "8": "A B C oB 7B pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "sC"
    },
    P: {
      "1": "I tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "1": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "1": "7C"
    }
  },
  B: 1,
  C: "Details & Summary elements"
};
export default exports;