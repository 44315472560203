var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 9B"
    },
    B: {
      "2": "C K L G M N O Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H",
      "16": "P"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 AC rB I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB BC CC"
    },
    D: {
      "2": "0 1 2 3 4 5 6 7 8 9 I s J D E F A B C K L G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC"
    },
    E: {
      "1": "B",
      "2": "I s J D E F A C K L G EC xB FC GC HC IC yB oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC"
    },
    F: {
      "2": "0 1 2 3 4 5 6 7 8 9 F B C G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b MC NC OC PC oB 7B QC pB"
    },
    G: {
      "2": "E xB RC 8B SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B"
    },
    H: {
      "2": "lC"
    },
    I: {
      "2": "rB I H mC nC oC pC 8B qC rC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C d oB 7B pB"
    },
    L: {
      "2": "H"
    },
    M: {
      "2": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "sC"
    },
    P: {
      "2": "I tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "2": "zB"
    },
    R: {
      "2": "6C"
    },
    S: {
      "2": "7C"
    }
  },
  B: 7,
  C: "Explicit descendant combinator >>"
};
export default exports;