var exports = {};
exports = {
  A: {
    A: {
      "2": "9B",
      "8": "J D E",
      "260": "F A B"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB",
      "2": "AC",
      "132": "rB BC CC",
      "260": "I s J D E F A B C K L G M N O t u"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB sB XB tB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q r c H vB wB DC",
      "132": "I s",
      "260": "J D E F A B C K L G M N O t u v w x y z"
    },
    E: {
      "1": "D E F A B C K L G GC HC IC yB oB pB zB JC KC 0B 1B 2B 3B qB 4B 5B 6B LC",
      "132": "I EC xB",
      "260": "s J FC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB d iB jB kB lB mB nB P Q R uB S T U V W X Y Z a b",
      "132": "F B MC NC OC PC",
      "260": "C oB 7B QC pB"
    },
    G: {
      "1": "E UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC kC 0B 1B 2B 3B qB 4B 5B 6B",
      "132": "xB",
      "260": "RC 8B SC TC"
    },
    H: {
      "132": "lC"
    },
    I: {
      "1": "H qC rC",
      "132": "mC",
      "260": "rB I nC oC pC 8B"
    },
    J: {
      "260": "D A"
    },
    K: {
      "1": "d",
      "132": "A",
      "260": "B C oB 7B pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "260": "A B"
    },
    O: {
      "1": "sC"
    },
    P: {
      "1": "I tC uC vC wC xC yB yC zC 0C 1C 2C qB 3C 4C 5C"
    },
    Q: {
      "1": "zB"
    },
    R: {
      "1": "6C"
    },
    S: {
      "1": "7C"
    }
  },
  B: 1,
  C: "HTML5 semantic elements"
};
export default exports;